(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var _faqSchemeGenerator = require('./faqSchemeGenerator');

var _faqSchemeGenerator2 = _interopRequireDefault(_faqSchemeGenerator);

require('./autoreg.js');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

$(document).ready(function () {
  $('#sql-text h1').appendTo($('.for-h'));
  $('#sql-text').appendTo($('.main-text'));
  // $('#sql-text').empty();

  // burger menu
  $('.burger').on('click', function () {
    $(this).toggleClass('burger_active');
    $('.menu__nav').slideToggle();
  });

  $('.search-form_toggle-icon').on('click', function () {
    $(this).toggleClass('open');
    $('.search-form').toggleClass('open');
  });

  // dropdown menu
  $('.header__menu .sub-menu').parent().addClass('has-children');

  $('.menu__nav .has-children').each(function () {
    var elem = $(this).find(':first');
    var clone = elem.clone();
    var submenu = $(this).find('.sub-menu');

    clone.prependTo(submenu);

    var container = $('<div/>').addClass('container');

    submenu.children().appendTo(container);
    container.appendTo(submenu);

    if (parseInt($(window).width()) <= 1500) {
      elem.attr('href', 'javascript:void(0);');
    }
  });

  // $('.menu__nav .nav-item.has-children').click(function () {
  //   if (parseInt($(window).width()) <= 1500) {
  //     $(this).find('.sub-menu').toggle();
  //   }
  // });

  // footer menu
  $('.footer__nav .sub-menu').parent().addClass('has-children');

  $('.footer__nav .has-children').each(function () {
    var elem = $(this).find(':first');

    if (parseInt($(window).width()) <= 1024) {
      elem.attr('href', 'javascript:void(0);');
    }
  });

  $('.footer__nav .nav-item.has-children').click(function () {
    if (parseInt($(window).width()) <= 1024) {
      $(this).find('.sub-menu').slideToggle();
    }
  });

  // hide/show menu
  var header = $('.header__top-wrapper'),
      scrollPrev = 0;

  $(window).scroll(function () {
    if ($('.burger').hasClass('burger_active')) {
      return false;
    } else {
      var scrolled = $(window).scrollTop();

      if (scrolled > 530 && scrolled > scrollPrev) {
        header.addClass('out');
      } else {
        header.removeClass('out');
      }
      scrollPrev = scrolled;
    }
  });

  $('[href="#popup"]').on('click', function () {
    $('.popup').show(200);
    $('.overlay').show(200);
    return false;
  });

  $('.popup__close').on('click', function () {
    $(this).parent().hide(200);
    $('.overlay').hide(200);
  });

  $('.overlay').on('click', function () {
    $('.popup').hide(200);
    $('.overlay').hide(200);
  });

  $('.js-comments-slider').slick({
    slidesToShow: 1,
    autoplay: false,
    dots: false,
    arrows: true
  });

  $('.comment-text > ul:last-of-type').empty();

  $('.faq-item_header').click(function () {
    if ($(this).parent().hasClass('active')) {
      $(this).parent().removeClass('active');
      $(this).next().slideToggle(200);
    } else {
      if (!$(this).closest('.faq-item').hasClass('toggle')) {
        $(this).parents('.faq-item').children().each(function () {
          if ($(this).hasClass('active')) {
            $(this).removeClass('active');
          }
        });
      }
      $(this).parent().addClass('active');
      $(this).next().slideToggle(200);
    }
  });

  (0, _faqSchemeGenerator2.default)('.faq-block', '.faq-item', '.faq-item_header', '.faq-item_content');

  // all categories button
  var menuList = $('.content-menu');
  var menuOthers = $('.content-menu_others');
  var container = $('.container');

  var maxWidth = container.outerWidth() - menuOthers.outerWidth() - 90;

  function responsive() {
    if (parseInt($(window).width()) >= 1024) {
      while (menuList.outerWidth() > maxWidth) {
        $('.others .others_list').prepend(menuList.find('li').last());
      }

      if ($('.others .others_list li').length > 0) {
        menuOthers.show();
      }
    }
  }
  responsive();

  $(window).resize(responsive);

  // TABS
  $('.casino-table-first_tabs').on('click', 'li:not(.active)', function () {
    $(this).addClass('active').siblings().removeClass('active').closest('.casino-table-first_tabs').find('.tabs__content').removeClass('active').eq($(this).index()).addClass('active');
  });
});

},{"./autoreg.js":2,"./faqSchemeGenerator":3}],2:[function(require,module,exports){
'use strict';

$('[data-link]').click(function () {
    var link = $(this).data('link');
    window.open('/' + atob(link), '_self');
});

},{}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Create FAQPage DATA in JSON-LD
 *
 * @param {array} data - an array with object of the form --> { name: value, text: value }
 * @return {string} - JSON string data
 */
var createJsonFAQ = function createJsonFAQ(data) {
  var FAQ = [];

  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var item = _step.value;
      var name = item.name,
          text = item.text;

      var FAQObj = '\n    {\n      "@type": "Question",\n      "name": "' + name.trim() + '",\n      "acceptedAnswer": {\n        "@type": "Answer",\n        "text": "' + text.trim() + '"\n      }\n    }\n    ';
      FAQ.push(JSON.parse(FAQObj));
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  var jsonScript = '\n  {\n    "@context": "https://schema.org",\n    "@type": "FAQPage",\n    "mainEntity": ' + JSON.stringify(FAQ) + '\n  }\n  ';
  return JSON.stringify(JSON.parse(jsonScript));
};

exports.default = function (blockSelector, itemSelector, questionSelector, answerSelector) {
  // get data from FAQ fields
  var faqData = [];
  var faqBlocks = document.querySelectorAll(blockSelector);

  faqBlocks.forEach(function (faq) {
    var faqItem = faq.querySelectorAll(itemSelector);
    faqItem.forEach(function (item) {
      var faqQuestion = item.querySelector(questionSelector);
      var faqAnswers = item.querySelector(answerSelector);
      var itemObj = {
        name: faqQuestion.textContent,
        text: faqAnswers.textContent
      };
      faqData.push(itemObj);
    });
  });

  // add script on the page in head
  var scriptTag = document.createElement('script');
  scriptTag.type = 'application/ld+json';

  var code = faqData.length != 0 ? createJsonFAQ(faqData) : false;

  if (code) {
    try {
      scriptTag.appendChild(document.createTextNode(code));
      document.head.appendChild(scriptTag);
    } catch (e) {
      scriptTag.text = code;
      document.head.appendChild(scriptTag);
    }
  }
};

},{}]},{},[1]);
